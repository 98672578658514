import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import Typewriter from 'vue-typewriter'
require('animate.css')
import VueAnimateOnScroll from 'vue-animate-onscroll'
Vue.use(VueAnimateOnScroll)
Vue.use(Typewriter)
Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
