<template>
    <div>
    <v-app-bar
      color="orange"
      dark
      fixed
      app
    >
    <v-app-bar-nav-icon @click.stop="drawer = !drawer" class="hidden-sm-and-up"></v-app-bar-nav-icon>
      <v-avatar size="40" :tile="true" class="mr-2">
      <img
        src="dggram.png"
        alt="logo"
        style="border-radius:50px"
      >
    </v-avatar>

      <v-toolbar-title>डिजिटल ग्रामपंचायत</v-toolbar-title>

      <v-spacer></v-spacer>
      <div class="hidden-sm-and-down">
      <v-btn text>
        मुख्यपृष्ठ
      </v-btn>
      <v-btn href="#user" text>
        ग्रामपंचायत ऍप
      </v-btn>
      <v-btn href="#admin" text>
       ग्रामपंचायत डॅशबोर्ड
      </v-btn>
      <v-btn href="#delivery" text>
        ग्रामपंचायत वेबसाईट
      </v-btn>
      <v-btn href="#pricing" text>
        प्लान
      </v-btn>
    </div>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      fixed
      temporary
    >
      <v-list
        nav
        dense
      >
        <v-list-item-group
          active-class="deep-orange--text text--accent-4"
        >
          <v-list-item>
            <v-list-item-title> मुख्यपृष्ठ</v-list-item-title>
          </v-list-item>

          <v-list-item href="#user">
            <v-list-item-title>ग्रामपंचायत ऍप</v-list-item-title>
          </v-list-item>

          <v-list-item href="#admin">
            <v-list-item-title>ग्रामपंचायत डॅशबोर्ड</v-list-item-title>
          </v-list-item>

          <v-list-item href="#delivery">
            <v-list-item-title>ग्रामपंचायत वेबसाईट</v-list-item-title>
          </v-list-item>
          <v-list-item href="#pricing">
            <v-list-item-title>प्लान</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
</div>
</template>
<script type="text/javascript">
    export default{
      data(){
        return {
          drawer:false
        }
      }
    }
</script>