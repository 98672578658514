<template>
    <v-container class="my-10">
        
        <v-row>
            <v-col md="3" sm="12" cols="12">
                <div class="feature d-flex flex-column text-center animate" v-animate-onscroll="'animate__animated  animate__bounceInUp '">
                    <div>
                        <v-icon
                            size="56"
                            color="orange"
                            >
                            mdi-android
                        </v-icon>
                    </div>
                    <div>
                        <h1 class="text-h5 font-weight-black">ग्रामपंचायत मोबाईल ऍप </h1>
                    </div>
                    <div>
                        <p>
                            गावकरी डिजिटल ग्रामपंचायत ऍप माध्यमातून त्यांचे गाव निवडून गावाची सर्व माहिती मिळवू शकतील.
                        </p>
                    </div>
                </div>
            </v-col>
            <v-col md="3" sm="12" cols="12">
                <div class="feature d-flex flex-column text-center animate" v-animate-onscroll="'animate__animated  animate__bounceInUp '">
                    <div>
                        <v-icon
                            size="56"
                            color="orange"
                            >
                            mdi-currency-inr
                        </v-icon>
                    </div>
                    <div>
                        <h1 class="text-h5 font-weight-black">ग्रामपंचायत डॅशबोर्ड</h1>
                    </div>
                    <div>
                        <p>
                            ग्रामपंचायतीला माहिती सहजरीत्या भरणे/बदल करणे किंवा काढून टाकण्यासाठी हे उपलब्ध करून देण्यात येत आहे.
                        </p>
                    </div>
                </div>
            </v-col>
            <v-col md="3" sm="12" cols="12">
                <div class="feature d-flex flex-column text-center animate" v-animate-onscroll="'animate__animated  animate__bounceInUp '">
                    <div>
                        <v-icon
                            size="56"
                            color="orange"
                            >
                            mdi-credit-card
                        </v-icon>
                    </div>
                    <div>
                        <h1 class="text-h5 font-weight-black">ऑनलाईन करभरणा</h1>
                    </div>
                    <div>
                        <p>
                            गावकर्यांना त्यांचा कर आता एका क्लिक वर बघता येणार आहे तसेच त्याचा भरणा सुद्धा करता येणार आहे.
                        </p>
                    </div>
                </div>
            </v-col>
            <v-col md="3" sm="12" cols="12" >
                <div class="feature d-flex flex-column text-center animate" v-animate-onscroll="'animate__animated  animate__bounceInUp '">
                    <div>
                        <v-icon
                            size="56"
                            color="orange"
                            >
                            mdi-infinity
                        </v-icon>
                    </div>
                    <div>
                        <h1 class="text-h5 font-weight-black">स्वतंत्र वेबसाईट</h1>
                    </div>
                    <div>
                        <p>
                           ग्रामपंचायतीची स्वताची वेबसाईट त्या गावाची डिजिटल ओळख करून देण्यास उपयुक्त ठरणार आहे.
                        </p>
                    </div>
                </div>
            </v-col>
            
        </v-row>
    
    </v-container>
</template>
<script type="text/javascript" scoped>
    export default{

    }
</script>