<template>
  <v-container>
    <v-row>
        <v-col>
    <v-card class="mb-4"
          >
            <div class="d-flex flex-no-wrap">
                <v-avatar
                class="ma-3"
                size="25%"
                tile
              >
                <v-img src="/userposter.png"></v-img>
              </v-avatar>
              <div>
                <v-card-title
                  class="headline"
                >
                    User Demo
                </v-card-title>

                <v-card-text>
                    <span class="subtitle-1">Link:- </span> <a :href="demo.user.link">Demo user app</a><br/>
                    <span class="subtitle-1">Phone Number:-</span>{{demo.user.phoneNumber}}<br/>
                    <span class="subtitle-1">OTP:-</span> {{demo.user.otp}}
                </v-card-text>
              </div>

              
            </div>
          </v-card>
          <v-card class="mb-4"
          >
            <div class="d-flex flex-no-wrap">
                <v-avatar
                class="ma-3"
                size="25%"
                tile
              >
                <v-img src="/adminposter.png"></v-img>
              </v-avatar>
              <div>
                <v-card-title
                  class="headline"
                >
                    Admin Demo
                </v-card-title>

               <v-card-text>
                    <span class="subtitle-1">Link:- </span> <a :href="demo.admin.link">Demo Admin</a><br/>
                    <span class="subtitle-1">Phone Number:-</span>{{demo.admin.phoneNumber}}<br/>
                    <span class="subtitle-1">OTP:-</span> {{demo.admin.otp}}
                </v-card-text>
              </div>

              
            </div>
          </v-card>
          <v-card class="mb-4"
          >
            <div class="d-flex flex-no-wrap">
                <v-avatar
                class="ma-3"
                size="25%"
                tile
              >
                <v-img src="/deliveryposter.png"></v-img>
              </v-avatar>
              <div>
                <v-card-title
                  class="headline"
                >
                    Delivery Demo
                </v-card-title>

                <v-card-text>
                    <span class="subtitle-1">Link:- </span> <a :href="demo.delivery.link">Demo Delivery App</a><br/>
                    <span class="subtitle-1">Phone Number:-</span>{{demo.delivery.phoneNumber}}<br/>
                    <span class="subtitle-1">OTP:-</span> {{demo.delivery.otp}}
                </v-card-text>
              </div>

              
            </div>
          </v-card>
      </v-col>
      </v-row>
  </v-container>
</template>
<script type="text/javascript">
import axios from 'axios'
    export default{
      data(){
        return {
          demo:{user:{},admin:{},delivery:{}}
        }
      },
      created(){
        axios.get('/demo.json').then(r=>{
          this.demo=r.data;
        })
      }
    }
</script>
