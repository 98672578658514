<template>
  <v-app id="grocart">
    <app-bar></app-bar>
    <v-main>
    <router-view></router-view>
    
  </v-main>
  <footer-bar></footer-bar>
  
  </v-app>
</template>
<style type="text/css">
  .animate:not(.animate__animated){
        visibility: hidden;
    }
    
  footer{
    z-index: 3;
  }
  @media only screen and (max-width: 768px) {
    .delay-1s{
    animation-delay: 0s;
  }
  .slower{
    animation-duration: 2s;
  }
}
</style>
<script type="text/javascript">
  import appBar from '@/components/AppBar'
  import footerBar from '@/components/Footer'
  export default{
    mounted(){

    },
    components:{appBar,footerBar},
  }
</script>

