<template>
  <v-container>
    <v-row>
      <v-col>
        <h1 class="text-h5 font-weight-black orange--text mb-6">FAQ's</h1>
  <v-expansion-panels>
    <v-expansion-panel
      v-for="(q,i) in faqs"
      :key="i"
      class="mb-4"
      style="border-radius:5px;"
      v-animate-onscroll="'animate__animated animate__zoomIn'"
      v-on:animationend="removeClass($event)"
    >
      <v-expansion-panel-header>{{q.q}}</v-expansion-panel-header>
      <v-expansion-panel-content>
        {{q.a}}
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</v-col>
</v-row>
</v-container>
</template>
<script type="text/javascript">
  export default{
      data(){
        return {
          faqs:[
            {
              q:'डिजिटल ग्रामपंचायत म्हणजे काय?',
              a:'डिजिटल ग्रामपंचायत ही संकल्पना डिजिटल इंडिया च ध्येय समोर ठेऊन तयार केलेली सॉफ्टवेअर प्रणाली आहे. याचा उद्देश गावांतील शेवटच्या घटका पर्यंत सर्व प्रकारची माहिती जी कि त्यांच्या आर्थिक आणि सामाजिक उत्कर्षासाठी उपयोगी पडेल.'
            },
            {
              q:'क्लासिक प्लान काय आहे ?',
              a:'यामध्ये आपण ग्रामपंचायतीला ऍप, डॅशबोर्ड, ई-दवंडी, विकासकामे, ई-भरणा व फोटो गॅलरी ही सुविधा उपलब्ध करून देतो. ही सेवा स:शुल्क असून ग्रामपंचायत पदाधिकारी आमच्याशी संपर्क करून या बद्दल ची अधिक माहिती घेऊ शकतात. '
            },
            {
              q:'गोल्ड प्लान काय आहे ?',
              a:'यामध्ये आपण ग्रामपंचायतीला ऍप, डॅशबोर्ड, ई-दवंडी, विकासकामे, ई-भरणा, फोटो गॅलरी व वेबसाईट ही सुविधा उपलब्ध करून देतो. ही सेवा स:शुल्क असून ग्रामपंचायत पदाधिकारी आमच्याशी संपर्क करून या बद्दल ची अधिक माहिती घेऊ शकतात. '
            },
           {
              q:'नि:शुल्क प्लान काय आहे ?',
              a:'यामध्ये आपण ग्रामपंचायतीला ऍप व डॅशबोर्ड  ही सुविधा उपलब्ध करून देतो. ही सेवा नि:शुल्क असून ग्रामपंचायत पदाधिकारी आमच्याशी संपर्क करून या बद्दल ची अधिक माहिती घेऊ शकतात. '
            },
            {
              q:'ऍप च्या माध्यमातून होणार्या वस्तू खरेदी ला जबाबदारी कोणाची असेल?',
              a:'आम्ही फक्त एक माध्यम उपलब्ध करून देतो. आमचा खरेदी व विक्रीचा कुठलाही संबध असणार नाही.'
            },
            
          ]
        }
      },
      mounted(){
        
      },
      methods:{
        removeClass(e){
          console.log(e)
        }
      }
  }
</script>