var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"my-10",attrs:{"id":"user"}},[_c('v-row',{staticClass:"fill-height align-center"},[_c('v-col',{directives:[{name:"animate-onscroll",rawName:"v-animate-onscroll",value:(
        'animate__animated  animate__fadeInLeft  animate__delay-1s'
      ),expression:"\n        'animate__animated  animate__fadeInLeft  animate__delay-1s'\n      "}],staticClass:"animate",attrs:{"cols":"12","md":"6"}},[_c('video',{staticStyle:{"max-width":"100%"},attrs:{"loop":"","muted":"","autoplay":"","playsinline":"","poster":"userposter.png"},domProps:{"muted":true}},[_c('source',{attrs:{"src":"https://firebasestorage.googleapis.com/v0/b/dg-gram.appspot.com/o/dggram_website_videos%2Fezgif.com-gif-maker%20(1).mp4?alt=media&token=886e4c09-9428-4c4f-aec6-8f019594d5b8","type":"video/mp4"}})])]),_c('v-col',{attrs:{"cols":"12","md":"6","order-sm":"2"}},[_c('h1',{directives:[{name:"animate-onscroll",rawName:"v-animate-onscroll",value:(
          'animate__animated  animate__fadeInUp  animate__delay-1s text-uppercase'
        ),expression:"\n          'animate__animated  animate__fadeInUp  animate__delay-1s text-uppercase'\n        "}],staticClass:"text-h5 font-weight-black orange--text mb-6 animate"},[_vm._v(" ग्रामपंचायत मोबाईल ऍप ")]),_c('div',{directives:[{name:"animate-onscroll",rawName:"v-animate-onscroll",value:(
          'animate__animated  animate__fadeInUp  animate__delay-1s'
        ),expression:"\n          'animate__animated  animate__fadeInUp  animate__delay-1s'\n        "}],staticClass:"d-flex flex-column feature mb-6 pb-4 first animate"},[_c('div',{staticClass:"text-h5 mb-2"},[_c('v-icon',{attrs:{"color":"orange"}},[_vm._v("mdi-checkbox-multiple-marked-circle")]),_vm._v(" शेतीविषयक माहिती ")],1),_c('div',[_vm._v(" बाजारभाव आणि शेती विषयक सल्ले मोफत उपलब्ध झाले मुळे शेतीच्या उत्पन्नात भर. ")])]),_c('div',{directives:[{name:"animate-onscroll",rawName:"v-animate-onscroll",value:(
          'animate__animated  animate__fadeInUp  animate__delay-1s'
        ),expression:"\n          'animate__animated  animate__fadeInUp  animate__delay-1s'\n        "}],staticClass:"d-flex flex-column feature mb-6 pb-4 animate"},[_c('div',{staticClass:"text-h5 mb-2"},[_c('v-icon',{attrs:{"color":"orange"}},[_vm._v("mdi-checkbox-multiple-marked-circle")]),_vm._v(" गावातील उद्योगांची माहिती ")],1),_c('div',[_vm._v("गावातील उद्योगांची विस्तृत माहिती एका क्लिक वर")])]),_c('div',{directives:[{name:"animate-onscroll",rawName:"v-animate-onscroll",value:(
          'animate__animated  animate__fadeInUp  animate__delay-1s'
        ),expression:"\n          'animate__animated  animate__fadeInUp  animate__delay-1s'\n        "}],staticClass:"d-flex flex-column feature mb-6 pb-4 animate"},[_c('div',{staticClass:"text-h5 mb-2"},[_c('v-icon',{attrs:{"color":"orange"}},[_vm._v("mdi-checkbox-multiple-marked-circle")]),_vm._v(" वस्तू खरेदी विक्री ")],1),_c('div',[_vm._v(" वस्तू, शेतीमाल , पशुधन इत्यादी खरेदी आणि विक्री करण्याची सुविधा ")])]),_c('div',{directives:[{name:"animate-onscroll",rawName:"v-animate-onscroll",value:(
          'animate__animated  animate__fadeInUp  animate__delay-1s'
        ),expression:"\n          'animate__animated  animate__fadeInUp  animate__delay-1s'\n        "}],staticClass:"d-flex flex-column feature mb-6 pb-4 animate"},[_c('div',{staticClass:"text-h5 mb-2"},[_c('v-icon',{attrs:{"color":"orange"}},[_vm._v("mdi-checkbox-multiple-marked-circle")]),_vm._v(" शासकीय योजनांची माहिती ")],1),_c('div',[_vm._v(" राज्य व केंद्र सरकार च्या विविध योजनांची विस्तृत माहिती ग्रामस्थांना या माध्यमातून सहजरीत्या मिळेल ")])]),_c('div',{directives:[{name:"animate-onscroll",rawName:"v-animate-onscroll",value:(
          'animate__animated  animate__fadeInUp  animate__delay-1s'
        ),expression:"\n          'animate__animated  animate__fadeInUp  animate__delay-1s'\n        "}],staticClass:"d-flex flex-column feature mb-6 pb-4 animate"},[_c('div',{staticClass:"text-h5 mb-2"},[_c('v-icon',{attrs:{"color":"orange"}},[_vm._v("mdi-checkbox-multiple-marked-circle")]),_vm._v(" नोकरीच्या संधी ")],1),_c('div',[_vm._v(" गावातील होतकरू तरुणांसाठी शासकीय व इतर नोकरीच्या बातम्या सहजरित्या उपलब्ध होतील. ")])]),_c('div',{directives:[{name:"animate-onscroll",rawName:"v-animate-onscroll",value:(
          'animate__animated  animate__fadeInUp  animate__delay-1s'
        ),expression:"\n          'animate__animated  animate__fadeInUp  animate__delay-1s'\n        "}],staticClass:"d-flex flex-column feature mb-6 pb-4 last animate"},[_c('div',{staticClass:"text-h5 mb-2"},[_c('v-icon',{attrs:{"color":"orange"}},[_vm._v("mdi-checkbox-multiple-marked-circle")]),_vm._v(" गावातील संस्थांची माहिती ")],1),_c('div',[_vm._v(" गावातील सर्व शैक्षणिक व आरोग्य सुविधा व संस्थांची माहिती एका क्लीक वर गावकर्यांना भेटेल. ")])])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }