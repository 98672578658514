<template>
  <v-container id="admin" class="my-10">
    <v-row class="fill-height align-center">
      <v-col cols="12" md="6">
        <h1
          class="text-h5 font-weight-black orange--text mb-6 animate"
          v-animate-onscroll="
            'animate__animated  animate__fadeInUp animate__delay-1s text-uppercase'
          "
        >
          ग्रामपंचायत डॅशबोर्ड
        </h1>
        <div
          class="d-flex flex-column feature mb-6 pb-4 first animate"
          v-animate-onscroll="
            'animate__animated  animate__fadeInUp animate__delay-1s'
          "
        >
          <div class="text-h5 mb-2">
            <v-icon color="orange">mdi-checkbox-multiple-marked-circle</v-icon>
            गावाच्या विकास कामांची माहिती
          </div>
          <div>
            ग्रामपंचायत ने केलेल्या गावाच्या विविध विकास कामांची माहिती
            लोकांपर्यंत पोहचवणे सहजरित्या शक्य होईल
          </div>
        </div>
        <div
          class="d-flex flex-column feature mb-6 pb-4 animate"
          v-animate-onscroll="
            'animate__animated  animate__fadeInUp animate__delay-1s'
          "
        >
          <div class="text-h5 mb-2">
            <v-icon color="orange">mdi-checkbox-multiple-marked-circle</v-icon>
            गावची दवंडी मोबाईल वर
          </div>
          <div>
            गावातील लोकांना काही सूचना करायच्या असल्यास ग्रामपंचायत सहजरित्या
            मोबाईल वर दवंडी पाठवून ती सूचना लोकांना काही क्षणात पाठवू शकते.
          </div>
        </div>
        <div
          class="d-flex flex-column feature mb-6 pb-4 animate"
          v-animate-onscroll="
            'animate__animated  animate__fadeInUp animate__delay-1s'
          "
        >
          <div class="text-h5 mb-2">
            <v-icon color="orange">mdi-checkbox-multiple-marked-circle</v-icon
            >शैक्षणिक,आरोग्य व सामाजिक संस्थांची माहिती
          </div>
          <div>
            ग्रामपंचायत गावातील विविध शैक्षणिक,आरोग्य व सामाजिक संस्थांची माहिती
            सहजरित्या उपलब्ध करून देईल.
          </div>
        </div>
        <div
          class="d-flex flex-column feature mb-6 pb-4 animate"
          v-animate-onscroll="
            'animate__animated  animate__fadeInUp animate__delay-1s'
          "
        >
          <div class="text-h5 mb-2">
            <v-icon color="orange">mdi-checkbox-multiple-marked-circle</v-icon
            >घरकर/ पाणीकर व्यवस्थापन
          </div>
          <div>
            ग्रामपंचायत सहजरीत्या विविध करांचे व्यवस्थापन या द्वारे करू शकेल.
          </div>
        </div>
      </v-col>

      <v-col
        cols="12"
        md="6"
        class="animate"
        v-animate-onscroll="
          'animate__animated  animate__fadeInRight animate__delay-1s'
        "
      >
        <video
          style="max-width: 100%"
          loop
          muted
          autoplay
          playsinline
          poster="adminposter.png"
        >
          <source
            src="https://firebasestorage.googleapis.com/v0/b/dg-gram.appspot.com/o/dggram_website_videos%2Fezgif.com-gif-maker%20(2).mp4?alt=media&token=ef66cbbd-b867-4a40-89b5-a709f73f6c86"
          />
        </video>
      </v-col>
    </v-row>
  </v-container>
</template>
<style type="text/css" scoped>
.feature:not(.last) {
  border-bottom: 1px solid black;
}
</style>
<script type="text/javascript">
export default {
  data() {
    return {
      show: false,
    };
  },
  methods: {
    onIntersect(e, s) {
      if (e[0].isIntersecting) this.show = true;
      console.log(e);
      console.log(s);
    },
  },
};
</script>
