<template>
  <v-speed-dial
      v-model="fab"
      direction="top"
      bottom
      right
      fixed
    >
      <template v-slot:activator>
        <v-btn
          v-model="fab"
          color="green"
          dark
          fab
          
        >
          <v-icon v-if="fab">mdi-close</v-icon>
          <v-icon v-else>mdi-phone</v-icon>
        </v-btn>
      </template>
      <v-btn
        fab
        dark
        small
        color="green"
        href="tel:+917559437623"
      >
        <v-icon>mdi-phone</v-icon>
      </v-btn>
      <v-btn
        fab
        dark
        small
        color="green darken-3"
        href=" https://wa.me/+917559437623"
        target="_blank"
      >
        <v-icon>mdi-whatsapp</v-icon>
      </v-btn>
      <v-btn
        fab
        dark
        small
        color="red"
        href="https://mail.google.com/mail/?view=cm&fs=1&to=rvscriptsystems@gmail.com"
        target="_blank"
      >
        <v-icon>mdi-gmail</v-icon>
      </v-btn>
    </v-speed-dial>
</template>
<script type="text/javascript">
  export default{
    data(){
      return {
        fab:true
      }
    }
  }
</script>