<template>
  <div>
    <v-dialog :value="value" @input="$emit('input', $event)" width="400">
      <v-card color="orange" dark>
        <v-card-title class="headline fill-width d-flex justify-space-between"
          ><span class="d-inline-block">संपर्क करा</span
          ><v-btn icon @click="$emit('input', false)" class="d-inline-block"
            ><v-icon size="24px">mdi-close</v-icon></v-btn
          ></v-card-title
        >
        <v-card-text class="pb-0">
          <v-container>
            <v-form ref="form">
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    label="नाव*"
                    :rules="rules.required"
                    v-model="contact.name"
                    outlined
                    dense
                    required
                  ></v-text-field>
                  <v-text-field
                    label="फोन नंबर*"
                    :rules="rules.phoneNumber"
                    v-model="contact.phoneNumber"
                    outlined
                    dense
                    required
                  ></v-text-field>
                  <div>{{ "आपण सरपंच अथवा ग्रामसेवक आहात  काय?" }}</div>
                  <v-radio-group
                    class="mt-0"
                    row
                    v-model="contact.sarpanch"
                    :rules="rules.required"
                  >
                    <v-radio label="होय" value="yes"></v-radio>
                    <v-radio label="नाही" value="No"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="white" class="orange--text" @click="submit()"
            >जतन करा</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog width="400" v-model="d">
      <v-card>
        <v-card-title><b>धन्यवाद! 🙏🏻 </b> </v-card-title>
        <v-card-text> आम्ही आपल्याला लवकरच संपर्क करू. </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="orange" text @click="d = false">ठीक</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
        color="orange"
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script type="text/javascript">
import axios from "axios";
export default {
  props: ["value"],
  data() {
    return {
      d: false,
      rules: {
        required: [(v) => !!v || "required"],
        email: [
          (v) => !!v || "ई-मेल गरजेचा आहे",
          (v) => /.+@.+/.test(v) || "E-mail must be valid",
        ],
        phoneNumber: [
          (v) => !!v || "कृपया संपर्क प्रविष्ठ करा",
          (v) => (v && /^[6-9]\d{9}$/.test(v)) || "चुकीचा संपर्क क्रमांक",
        ],
      },
      contact: {
        name: "",
        email: "",
        phoneNumber: "",
        sarpanch: null,
      },
      loading: false,
    };
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.$emit("input", false);
        axios
          .post(
            `https://api.dggram.com/crm/opportunities`,
            {
              name: this.contact.name,
              description: `Phone Number :${this.contact.phoneNumber} \n Sarpanch or Gramsevak: ${this.contact.sarpanch}`,
            },
            {
              headers: {
                version: 1,
              },
            }
          )
          .then(() => {
            this.loading = false;
            this.d = true;
            this.contact = {
              name: "",
              email: "",
              phoneNumber: "",
              sarpanch: null,
            };
          });
      }
    },
  },
};
</script>
