var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"my-10",attrs:{"id":"admin"}},[_c('v-row',{staticClass:"fill-height align-center"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('h1',{directives:[{name:"animate-onscroll",rawName:"v-animate-onscroll",value:(
          'animate__animated  animate__fadeInUp animate__delay-1s text-uppercase'
        ),expression:"\n          'animate__animated  animate__fadeInUp animate__delay-1s text-uppercase'\n        "}],staticClass:"text-h5 font-weight-black orange--text mb-6 animate"},[_vm._v(" ग्रामपंचायत डॅशबोर्ड ")]),_c('div',{directives:[{name:"animate-onscroll",rawName:"v-animate-onscroll",value:(
          'animate__animated  animate__fadeInUp animate__delay-1s'
        ),expression:"\n          'animate__animated  animate__fadeInUp animate__delay-1s'\n        "}],staticClass:"d-flex flex-column feature mb-6 pb-4 first animate"},[_c('div',{staticClass:"text-h5 mb-2"},[_c('v-icon',{attrs:{"color":"orange"}},[_vm._v("mdi-checkbox-multiple-marked-circle")]),_vm._v(" गावाच्या विकास कामांची माहिती ")],1),_c('div',[_vm._v(" ग्रामपंचायत ने केलेल्या गावाच्या विविध विकास कामांची माहिती लोकांपर्यंत पोहचवणे सहजरित्या शक्य होईल ")])]),_c('div',{directives:[{name:"animate-onscroll",rawName:"v-animate-onscroll",value:(
          'animate__animated  animate__fadeInUp animate__delay-1s'
        ),expression:"\n          'animate__animated  animate__fadeInUp animate__delay-1s'\n        "}],staticClass:"d-flex flex-column feature mb-6 pb-4 animate"},[_c('div',{staticClass:"text-h5 mb-2"},[_c('v-icon',{attrs:{"color":"orange"}},[_vm._v("mdi-checkbox-multiple-marked-circle")]),_vm._v(" गावची दवंडी मोबाईल वर ")],1),_c('div',[_vm._v(" गावातील लोकांना काही सूचना करायच्या असल्यास ग्रामपंचायत सहजरित्या मोबाईल वर दवंडी पाठवून ती सूचना लोकांना काही क्षणात पाठवू शकते. ")])]),_c('div',{directives:[{name:"animate-onscroll",rawName:"v-animate-onscroll",value:(
          'animate__animated  animate__fadeInUp animate__delay-1s'
        ),expression:"\n          'animate__animated  animate__fadeInUp animate__delay-1s'\n        "}],staticClass:"d-flex flex-column feature mb-6 pb-4 animate"},[_c('div',{staticClass:"text-h5 mb-2"},[_c('v-icon',{attrs:{"color":"orange"}},[_vm._v("mdi-checkbox-multiple-marked-circle")]),_vm._v("शैक्षणिक,आरोग्य व सामाजिक संस्थांची माहिती ")],1),_c('div',[_vm._v(" ग्रामपंचायत गावातील विविध शैक्षणिक,आरोग्य व सामाजिक संस्थांची माहिती सहजरित्या उपलब्ध करून देईल. ")])]),_c('div',{directives:[{name:"animate-onscroll",rawName:"v-animate-onscroll",value:(
          'animate__animated  animate__fadeInUp animate__delay-1s'
        ),expression:"\n          'animate__animated  animate__fadeInUp animate__delay-1s'\n        "}],staticClass:"d-flex flex-column feature mb-6 pb-4 animate"},[_c('div',{staticClass:"text-h5 mb-2"},[_c('v-icon',{attrs:{"color":"orange"}},[_vm._v("mdi-checkbox-multiple-marked-circle")]),_vm._v("घरकर/ पाणीकर व्यवस्थापन ")],1),_c('div',[_vm._v(" ग्रामपंचायत सहजरीत्या विविध करांचे व्यवस्थापन या द्वारे करू शकेल. ")])])]),_c('v-col',{directives:[{name:"animate-onscroll",rawName:"v-animate-onscroll",value:(
        'animate__animated  animate__fadeInRight animate__delay-1s'
      ),expression:"\n        'animate__animated  animate__fadeInRight animate__delay-1s'\n      "}],staticClass:"animate",attrs:{"cols":"12","md":"6"}},[_c('video',{staticStyle:{"max-width":"100%"},attrs:{"loop":"","muted":"","autoplay":"","playsinline":"","poster":"adminposter.png"},domProps:{"muted":true}},[_c('source',{attrs:{"src":"https://firebasestorage.googleapis.com/v0/b/dg-gram.appspot.com/o/dggram_website_videos%2Fezgif.com-gif-maker%20(2).mp4?alt=media&token=ef66cbbd-b867-4a40-89b5-a709f73f6c86"}})])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }