<template>
  <div>
    <cover @get="contact = true"></cover>
    <features></features>
    <user></user>
    <admin></admin>
    <website></website>
    <pricing @get="contact = true"></pricing>
    <faq></faq>
    <contact v-model="contact"></contact>
    <fab></fab>
  </div>
</template>
<script type="text/javascript">
import cover from "./Cover";
import features from "./Features";
import user from "./User";
import admin from "./Admin";
import website from "./Website";
import pricing from "./Pricing";
import faq from "./Faq";
import contact from "@/components/Contact";
import fab from "@/components/Fab";
export default {
  data() {
    return {
      contact: true,
    };
  },
  methods: {},
  components: {
    cover,
    features,
    user,
    admin,
    website,
    pricing,
    faq,
    contact,
    fab,
  },
};
</script>
