<template>
<v-container fluid>
    <v-row no-gutters class="slider align-center" >
        <v-col sm="12" md="5" cols="12" class="pl-6">
          <h1 class="text-md-h2 text-sm-h4">आता बनवा आपली ग्रामपंचायत डिजिटल...<span style="display:none;">डिजिटल ग्रामपंचायत</span></h1>
          <h1 class="text-md-h3 text-sm-h5 font-weight-black mb-2">
            <typewriter
              :speed="50"
              :full-erase="true"
              :interval="600"
              :words='["डिजिटल ग्रामपंचायत","डिजिटल गाव","ई-ग्राम", "स्मार्ट ग्राम"]'>
      </typewriter>
      </h1>
      <p class="text-justify">
        जो पर्यंत गाव डिजिटल होत नाही तो पर्यंत देश डिजिटल होऊ शकत नाही. याच उद्देश्याने प्रेरित होऊन आम्ही घेऊन आलो आहोत डिजिटल ग्रामपंचायत ऍप आणि वेबसाईट.आपलं गाव स्मार्ट आणि डिजिटल बनवण्यासाठी आजच आमच्याशी संपर्क करा.
      </p>
      <v-btn color="orange" href="https://play.google.com/store/apps/details?id=com.rvscript.digigram" x-large rounded outlined class="mr-2">ऍप लिंक</v-btn> 
      <v-btn color="orange" @click="$emit('get')" x-large rounded dark>संपर्क करा</v-btn>

        </v-col>
        <v-col sm="12" md="7" cols="12" class="d-flex justify-center">
          <v-img
            src="cover.webp"
            max-width="100%"
            class="animate__animated  animate__slideInRight"
            />
        </v-col>
        
    </v-row>
    <ul class="circles">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
            </ul>
  </v-container>

</template>
<style type="text/css">
    .slider{
        height:calc(100vh - 56px);
        position: relative;
        z-index: 3;
    }
    .circles{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 1;

}
@media only screen and (max-width: 768px) {
   .circles{
    z-index: 0 !important;
   }
}
.circles li{
    position: fixed;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(255, 140, 0, 0.2);
    animation: animate 25s linear infinite;
    bottom: -150px;
    
}

.circles li:nth-child(1){
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
}


.circles li:nth-child(2){
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
}

.circles li:nth-child(3){
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
}

.circles li:nth-child(4){
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
}

.circles li:nth-child(5){
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
}

.circles li:nth-child(6){
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
}

.circles li:nth-child(7){
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
}

.circles li:nth-child(8){
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
}

.circles li:nth-child(9){
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
}

.circles li:nth-child(10){
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
}



@keyframes animate {

    0%{
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100%{
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }

}
</style>
<script>
// @ is an alias to /src


export default {
 
}
</script>
