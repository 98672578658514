<template>
    <v-container class="my-10" id="pricing">
         <v-row>
            <v-col>
                <h1 class="text-h5 font-weight-black orange--text mb-6">प्लान्स</h1>
            </v-col>
        </v-row>
       
        <v-row>
            <v-col cols="12" sm="12" md="4" class="d-flex justify-center">
                <v-card class="elevation-6 pricing-card animate" v-animate-onscroll="'animate__animated  animate__bounceInUp '">
                        <v-card-title class="text-center mt-2">
                        <h1 class="text-center text-h5 font-weight-black font-weight-bold white--text text-uppercase mb-1" style="width:100%;">नि:शुल्क</h1>
                        <p class="white--text text-center text-body-2 fill-width">
                            नि:शुल्क. अधिक माहिती साठी संपर्क करा
                        </p>
                    </v-card-title>
                    <div class="ribbon"><span>त्वरा करा</span></div>
                    <v-card-text class="text-center white--text text-center">
                        <v-divider light class="my-6"></v-divider>
                        <p>ग्रामपंचायत ऍप</p>
                        <p>ग्रामपंचायत डॅशबोर्ड</p>
                        <p>गावाबद्दलची माहिती</p>
                        <p>ग्रामपंचायत पदाधिकारी यादी</p>
                        <p>गावातील शाळा व आरोग्य संस्था</p>
                        <p>नोकरीच्या बातम्या</p>
                        <p>शेतीविषयक मार्गदर्शन</p>
                         <p>मार्केट - खरेदी-विक्री</p>
                        <p>गावातील उद्योग</p>
                        <p>सरकारी योजनांची माहिती</p>
                        
                        
                       <v-btn large color="white" @click="$emit('get')" class="orange--text" rounded>संपर्क करा</v-btn>
                    </v-card-text>
                </v-card>
            </v-col>
            
            <v-col cols="12" sm="12" md="4" class="d-flex justify-center">
                <v-card class="elevation-6 pricing-card animate" v-animate-onscroll="'animate__animated  animate__bounceInUp '">
                    
                    <v-card-title class="text-center mt-2">
                        <h1 class="text-center text-h5 font-weight-black font-weight-bold white--text text-uppercase mb-1" style="width:100%;">क्लासिक</h1>
                        <p class="white--text text-center text-body-2 fill-width">
                            शुल्का संदर्भातील माहिती साठी संपर्क करा
                        </p>
                    </v-card-title>
                    <div class="ribbon"><span>त्वरा करा</span></div>
                    <v-card-text class="text-center white--text text-center">
                        
                        <v-divider light class="my-6"></v-divider>
                        <p>ग्रामपंचायत ऍप</p>
                        <p>ग्रामपंचायत डॅशबोर्ड</p>
                        <p> निशुल्क प्लान चे सर्व फीचर्स</p>
                        <p>ऑनलाईन करभरणा</p>
                        <p>ई-दवंडी</p>
                        <p>ग्रामपंचायतीने केलेली विकासकामे</p>
                        <p>ग्रामपंचायत प्रशासन</p>
                        <p>फोटो गॅलरी</p>
                       <v-btn large color="white" @click="$emit('get')" class="orange--text" rounded>संपर्क करा</v-btn>
                    </v-card-text>
                </v-card>
            </v-col>
             
            <v-col cols="12" sm="12" md="4" class="d-flex justify-center">
                <v-card class="elevation-6 pricing-card animate" v-animate-onscroll="'animate__animated  animate__bounceInUp '">
                    
                    <v-card-title class="text-center mt-2">
                        <h1 class="text-center text-h5 font-weight-black font-weight-bold white--text text-uppercase mb-1" style="width:100%;">गोल्ड</h1>
                        <p class="white--text text-center text-body-2 fill-width">
                            शुल्का संदर्भातील माहिती साठी संपर्क करा
                        </p>
                    </v-card-title>
                    <div class="ribbon"><span>त्वरा करा</span></div>
                    <v-card-text class="text-center white--text text-center">
                       
                        <v-divider light class="my-6"></v-divider>
                        <p>ग्रामपंचायत ऍप</p>
                        <p>ग्रामपंचायत डॅशबोर्ड</p>
                        <p> निशुल्क प्लान चे सर्व फीचर्स</p>
                        <p>ऑनलाईन करभरणा</p>
                        <p>ई-दवंडी</p>
                        <p>ग्रामपंचायतीने केलेली विकासकामे</p>
                        <p>फोटो गॅलरी</p>
                        <p>ग्रामपंचायत प्रशासन</p>
                        <p>ग्रामपंचायत वेबसाईट</p>
                        <v-btn large color="white" @click="$emit('get')" class="orange--text" rounded>संपर्क करा</v-btn>
                    </v-card-text>
                </v-card>
            </v-col>
             
            
            </v-row>
    </v-container>
</template>
<style type="text/css">
    .pricing-card{
        background-color: #FD940A;
        background-image: radial-gradient(circle, #F9A72B 0%, #FA9026 70%, #FB6C1F 100%);
        width:300px;
        border-radius: 30px !important;
        z-index: 3;
    }
    .fill-width{
        width:100%;
    }
    .ribbon {
   position: absolute;
   left: -5px; top: -5px;
   z-index: 1;
   overflow: hidden;
   width: 75px; height: 75px; 
   text-align: left;
}
.ribbon span {
   font-size: 10px;
   color: #fff; 
   text-transform: uppercase; 
   text-align: center;
   font-weight: bold; line-height: 20px;
   transform: rotate(-45deg);
   -webkit-transform: rotate(-45deg); /* Needed for Safari */
   width: 100px; display: block;
   background: #79A70A;
   background: linear-gradient(#F70505 0%, #8F0808 100%);
   box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
   position: absolute;
   top: 19px; left: -21px;
}
.ribbon span::after {
   content: '';
   position: absolute; 
   left: 0px; top: 100%;
   z-index: -1;
   border-left: 3px solid #79A70A;
   border-right: 3px solid transparent;
   border-bottom: 3px solid transparent;
   border-top: 3px solid #79A70A;
}
.ribbon span::before {
   content: '';
   position: absolute; 
   right: 0%; top: 100%;
   z-index: -1;
   border-right: 3px solid #79A70A;
   border-left: 3px solid transparent;
   border-bottom: 3px solid transparent;
   border-top: 3px solid #79A70A;
}
.ribbon span {background: red;}
.ribbon span::after {border-left-color: #8F0808; border-top-color: #8F0808;}
.ribbon span::before {border-right-color: #8F0808; border-top-color: #8F0808;}
</style>
<script type="text/javascript">
    export default{

    }
</script>